import React from 'react';
import { styled, Box, Button, Typography } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import RegistrationField from 'registration/RegistrationField';
import { FieldTypes, fieldValidator } from 'registration/RegistrationUtils';
import { useNotification } from 'utility/notification';
import { Trans, t } from '@lingui/macro';


export default function Registration(props) {
    const { form, mode, onFormChange, onSubmit, onCancel } = props;

    const { createNotification } = useNotification();


    const handleAttendeeUpdate = (value) => {
        const attendees = form.map(a => {
            if (a.tempId === value.tempId) {
                return value;
            } else {
                return a;
            }
        });

        onFormChange(attendees);
    };


    // ***** Validate Form Values ***** //

    const handleValidation = () => {

        let formError = false;

        const newAttendeeList = [];


        // Map over every attendee form
        for (const attendee of form) {

            let requiredForm = [];
            let customForm = [];

            // Map over every field, and check for errors
            // Assemble a new form array containing any new error messages
            for (const field of attendee.requiredForm) {
                const { error, errorMsg } = fieldValidator(field.value, field.type, field.required);
    
                if (error) {
                    formError = true;
                    requiredForm.push({ ...field, errorMsg })
                } else {
                    requiredForm.push(field)
                }
            }
            for (const field of attendee.customForm) {
                const { error, errorMsg } = fieldValidator(field.value, field.type, field.required);
    
                if (error) {
                    formError = true;
                    customForm.push({ ...field, errorMsg })
                } else {
                    customForm.push(field)
                }
            }

            newAttendeeList.push({ ...attendee, requiredForm, customForm });
        }

        
        if (formError === false) {
            onSubmit();
        } else {
            onFormChange(newAttendeeList);
            createNotification(t`Please ensure the form is filled in correctly`, { variant: 'warning' })
        }
    };


    return (
        <Root>
            <Content>

                {form.map((attendee) => (
                    <AttendeeForm
                        attendee={attendee}
                        mode={mode}
                        onAttendeeUpdate={handleAttendeeUpdate}
                    />
                ))}

                <Box display='flex' justifyContent='flex-end' mt={4}>
                    <CancelButton onClick={onCancel}><Trans>Cancel</Trans></CancelButton>
                    <SubmitButton onClick={handleValidation} color='primary'><Trans>Continue</Trans></SubmitButton>
                </Box>
                
            </Content>
        </Root>
    )
}


function AttendeeForm(props) {
    const { attendee, mode, onAttendeeUpdate } = props;

    const requiredForm = attendee.requiredForm;
    const customForm = attendee.customForm;


    // ***** Process Form Value Changes ***** //

    const handleRequiredFormChange = (index, value, optionId) => {
        const newForm = Array.from(requiredForm);

        switch (requiredForm[index].type) {
            case FieldTypes.AGE:
                newForm[index].value = value.replace(/[^0-9]/g,'').slice(0,3);
                break;

            case FieldTypes.MULTIPLE_CHOICE:
                value === true
                    ? newForm[index].value.add(optionId)
                    : newForm[index].value.delete(optionId)
                break;

            default:
                newForm[index].value = value;
                break;
        }

        newForm[index].errorMsg = null;

        onAttendeeUpdate({ ...attendee, requiredForm: newForm });
    };

    const handleCustomFormChange = (index, value, optionId) => {
        const newForm = Array.from(customForm);

        switch (customForm[index].type) {
            case FieldTypes.AGE:
                newForm[index].value = value.replace(/[^0-9]/g,'').slice(0,3);
                break;

            case FieldTypes.MULTIPLE_CHOICE:
                value === true
                    ? newForm[index].value.add(optionId)
                    : newForm[index].value.delete(optionId)
                break;

            default:
                newForm[index].value = value;
                break;
        }

        newForm[index].errorMsg = null;

        onAttendeeUpdate({ ...attendee, customForm: newForm });
    };


    const containsRequiredFields = requiredForm.length > 0 || customForm.some(f => Boolean(f.required) === true);


    return (
        <AttendeeFormContainer>
            {mode === 2 && (
                <CardHeader
                    title={attendee.productName}
                    subheader={t`Attendee` +  ' #' + attendee.tempId}
                />
            )}
            <CardContent>
                {requiredForm.map((field, index) => (
                    <RegistrationField
                        key={index}
                        index={index}
                        onChange={handleRequiredFormChange}
                        {...field}
                    />
                ))}
                {customForm.map((field, index) => (
                    <RegistrationField
                        key={index}
                        index={index}
                        onChange={handleCustomFormChange}
                        {...field}
                    />
                ))}
            </CardContent>
            {containsRequiredFields && (
                <Box ml={2} mb={1}>
                    <RequiredText variant='body2'>
                        <Trans>Fields marked with an asterisk are required</Trans>
                    </RequiredText>
                    <Required />
                </Box>
            )}
        </AttendeeFormContainer>
    )
}


const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    backgroundColor: '#f0f0f0',
    padding: theme.spacing(2),
    paddingBottom: 270
}));

const Content = styled('div')(({ theme }) => ({
    width: theme.breakpoints.values.md,
    marginRight: 'auto',
    marginLeft: 'auto'
}));

const AttendeeFormContainer = styled(Card)(({ theme }) => ({
    marginTop: theme.spacing(4)
}));

const SubmitButton = styled(Button)(({ theme }) => ({
    marginLeft: theme.spacing(2)
}));
SubmitButton.defaultProps = { variant: 'contained' };

const CancelButton = styled(Button)(({ theme }) => ({
    borderColor: theme.palette.warning.dark,
    color: theme.palette.warning.dark
}));
CancelButton.defaultProps = { variant: 'outlined' };

const RequiredText = styled(Typography)(({ theme }) => ({
    whiteSpace: 'pre-wrap',
}));
RequiredText.defaultProps = { display: 'inline' };

const Required = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.error.dark,
    marginLeft: theme.spacing(1)
}));
Required.defaultProps = { display: 'inline', children: '*' };
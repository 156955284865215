import { t } from '@lingui/macro';
import dayjs from 'dayjs';



export const FieldTypes = {
    'SHORT_ANSWER': 1,
    'PARAGRAPH': 2,
    'SINGLE_CHOICE': 3,
    'MULTIPLE_CHOICE': 4,
    'DROPDOWN': 5,
    'FULL_DATE': 6,
    'AGE': 7,
    'YES_OR_NO': 8,
    'SHORT_DATE': 9,
    'TERMS_AND_CONDITIONS': 10,
    'TEXT_BLOCK': 999,
    'IMAGE_BLOCK': 998
}


export function isRegistrationRequired(mode, questions, required, products, bundles) {
    // RETURN: There are no questions available
    if (Array.isArray(questions) === false || questions.length === 0) {
        return false;
    }

    // ** Get IDs of products eligible for registration questions ** //

    const productIds = new Set();

    // By Order Mode: All tickets in cart are eligible
    if (mode === 1) {
        for (const p of products) {
            if (p.qty > 0) {
                productIds.add(p.id)
            }
        }
        for (const b of bundles) {
            if (b.qty > 0) {
                for (const bp of b.products) {
                    productIds.add(bp.id)
                }
            }
        }
    }
    // By Attendee Mode: Only tickets marked as attendees are eligible
    else if (mode === 2) {

        for (const p of products) {
            if (p.qty > 0 && p.isAttendee === true) {
                productIds.add(p.id)
            }
        }
        for (const b of bundles) {
            if (b.qty > 0) {
                for (const bp of b.products) {
                    if (bp.isAttendee === true) {
                        productIds.add(bp.id)
                    }
                }
            }
        }
        // RETURN: Attendees exist, and there are required questions for them to answer
        if (required.length > 0 && productIds.size > 0) {
            return true;
        }
    }

    // RETURN: There are no tickets eligible for registration questions
    if (productIds.size === 0) {
        return false;
    }


    // *** Check if Registration Questions apply to any of the tickets *** //

    for (const q of questions) {
        if (Array.isArray(q.question_products) && q.question_products.length > 0) {
            // This question is only applicable to specific ticket types
            for (const p of productIds) {
                for (const qp of q.question_products) {
                    if (p === qp) {
                        // RETURN: If there's a match then we will ask the question
                        return true;
                    }
                }
            }
        } else {
            // RETURN: This question can be asked for any ticket in the cart
            return true;
        }
    }

    // RETURN: Could not find any questions that apply to tickets in the cart
    return false;
}


export function initializeAttendee(mode, products, bundles, customQuestions, requiredQuestions) {
    // ** Generate Attendee List ** //
    let attendees = [];

    if (mode === 1) {
        // By Order: We pass in the IDs of all tickets in the users cart
        // This will be used if there are questions that can only be answered when buying specific tickets
        const productIds = new Set();

        for (const p of products) {
            if (p.qty > 0) {
                productIds.add(p.id)
            }
        }
        for (const b of bundles) {
            if (b.qty > 0) {
                for (const bp of b.products) {
                    productIds.add(bp.id)
                }
            }
        }

        attendees.push({
            tempId: 1,
            productId: null,
            productName: null,
            requiredForm: [],
            customForm: initializeAttendeeForm(customQuestions, Array.from(productIds))
        })
    }
    else if (mode === 2) {
        // By Attendee: We pass in just the ID of the attendee ticket. Not the other tickets in the order.
        for (const p of products) {
            if (p.qty > 0 && p.isAttendee === true) {
                for (let i = 0; i < p.qty; i++) {
                    attendees.push({
                        //tempId is assinged later
                        productId: p.id,
                        productName: p.name,
                        requiredForm: initializeAttendeeForm(requiredQuestions, []),
                        customForm: initializeAttendeeForm(customQuestions, [p.id])
                    })
                }
            }
        }
        // By Attendee: We pass in just the ID of the attendee ticket. Not the other tickets in the order.
        for (const b of bundles) {
            for (const bp of b.products) {
                if (bp.isAttendee === true) {
                    const pCount = b.qty * bp.qty;
                    for (let i = 0; i < pCount; i++) {
                        attendees.push({
                            //tempId is assinged later
                            productId: bp.id,
                            productName: bp.name,
                            requiredForm: initializeAttendeeForm(requiredQuestions, []),
                            customForm: initializeAttendeeForm(customQuestions, [bp.id])
                        })
                    }
                }
            }
        }

        attendees = attendees.sort((a,b) => a.productId - b.productId);

        // Assign tempId values after the attendees are placed in order by product type
        for (let i = 1; i <= attendees.length; i++) {
            attendees[i-1].tempId = i;
        }
    }

    return attendees;
}


// Format registration questions to be used as form state
export function initializeAttendeeForm(questions, productIds) {
    // Its important that we create a new instance of the form and its fields.
    // This avoids bugs that occur when elements in a complex/deeply nested object share the same references

    // We use a deep clone of the questions list as a base for the form
    const checkoutQuestions = JSON.parse(JSON.stringify(questions));
    const form = [];

    for (const cq of checkoutQuestions) {
        
        const field = cq;

        // Check if this question is limited to certain ticket types
            // If question_products contains product IDs, then there needs to be at least one of those product IDs in the attendee's cart before we can ask this question
            // If questin_products does not contain any product IDs then we ask the question no matter what

        const questionProducts = field.question_products;

        
        if (Array.isArray(questionProducts) && questionProducts.length > 0) {

            let skipQuestion = true;

            for (const p of productIds) {
                for (const qp of questionProducts) {
                    if (p === qp) {
                        // If there's a match then we ask the question
                        skipQuestion = false;
                    }
                }
            }

            // Skip the question
            if (skipQuestion === true) {
                continue;
            };
        }

        // Init errorMsg property
        field.errorMsg = null;

        // Set the field default value
        switch (field.type) {
            case FieldTypes.MULTIPLE_CHOICE:
                field.value = new Set();
                break;

            case FieldTypes.SHORT_ANSWER:
            case FieldTypes.PARAGRAPH:
            case FieldTypes.AGE:
            case FieldTypes.DROPDOWN:
                field.value = '';
                break;

            case FieldTypes.TERMS_AND_CONDITIONS:
                field.value = false;
                break;

            // YES_OR_NO: Neither option is selected by default
            default:
                field.value = null;
                break;
        }
        
        form.push(field);
    }

    return form;
}


export function fieldValidator(value, type, required) {
    switch (type) {

        case FieldTypes.SHORT_ANSWER:
        case FieldTypes.PARAGRAPH:
            if (required && value.trim().length === 0) {
                return { error: true, errorMsg: t`This field is required`}
            } else if (value.trim().length > 2000) {
                return { error: true, errorMsg: t`This field cannot contain more than 2000 characters` }
            } else {
                return { error: false, errorMsg: null }
            }

        case FieldTypes.SINGLE_CHOICE: // null if none selected
        case FieldTypes.DROPDOWN: // empty string if none selected
            if (required && !value) {
                return { error: true, errorMsg: t`This field is required`}
            } else {
                return { error: false, errorMsg: null }
            }

        case FieldTypes.MULTIPLE_CHOICE:
            if (required && value.size === 0) {
                return { error: true, errorMsg: t`This field is required` }
            } else {
                return { error: false, errorMsg: null }
            }
        
        case FieldTypes.FULL_DATE:
        case FieldTypes.SHORT_DATE:
            if (required && !value) {
                return { error: true, errorMsg: t`This field is required` }
            }
            if (!required && !value) {
                return { error: false, errorMsg: null }
            }
            else if (dayjs(value).isValid() === false) {
                return { error: true, errorMsg: t`Please enter a valid date` }
            }
            else {
                return { error: false, errorMsg: null }
            }
        
        case FieldTypes.AGE:

            if (isNaN(value)) {
                return { error: true, errorMsg: t`Please enter a valid number` }
            }
            if (required && !value) {
                return { error: true, errorMsg: t`This field is required` }
            }
            if (!required && !value) {
                return { error: false, errorMsg: null }
            }
            if (Number(value) < 1 || Number(value > 120)) {
                return { error: true, errorMsg: t`Please enter a valid number` }
            }
            return { error: false, errorMsg: null }
        
        case FieldTypes.YES_OR_NO:
            if (required && typeof value !== 'boolean') {
                return { error: true, errorMsg: t`This field is required` }
            } else {
                return { error: false, errorMsg: null }
            }

        case FieldTypes.TERMS_AND_CONDITIONS:
            if (required && value !== true) {
                return { error: true, errorMsg: t`This field is required` }
            } else {
                return { error: false, errorMsg: null }
            }

        case FieldTypes.TEXT_BLOCK:
        case FieldTypes.IMAGE_BLOCK:
            return { error: false, errorMsg: null }

        default:
            break;
    }
}


export const formatRegistrationFormForPurchase = (registrationForm) => {

    if (Array.isArray(registrationForm) === false) {
        return null;
    }

    let formattedRegistrationForm = [];

    for (const attendee of registrationForm) {

        const requiredForm = formatResponses(attendee.requiredForm);
        const customForm = formatResponses(attendee.customForm);

        formattedRegistrationForm.push({
            productId: attendee.productId,
            requiredForm: requiredForm,
            customForm: customForm,
        })
    }

    return formattedRegistrationForm;
};


function formatResponses(form) {
    return form.reduce((accumulator, field) => {

        let value = null;

        switch (field.type) {

            case FieldTypes.SHORT_ANSWER:
            case FieldTypes.PARAGRAPH:
                if (field.value.trim()) {
                    value = field.value.trim();
                }
                break;

            case FieldTypes.SINGLE_CHOICE:
            case FieldTypes.DROPDOWN:
                if (field.value) {
                    value = field.value;
                }
                break;

            case FieldTypes.AGE:
                if (field.value) {
                    value = Number(field.value);
                }
                break;

            case FieldTypes.FULL_DATE:
                if (field.value) {
                    value = dayjs(field.value).format('YYYY-MM-DD');
                }
                break;

            case FieldTypes.SHORT_DATE:
                if (field.value) {
                    value = dayjs(field.value).format('YYYY-MM-01'); // Only set the year + month. Day is always 01
                }
                break;

            case FieldTypes.MULTIPLE_CHOICE:
                if (field.value.size) {
                    value = Array.from(field.value);
                }
                break;

            case FieldTypes.YES_OR_NO:
            case FieldTypes.TERMS_AND_CONDITIONS:
                if (typeof field.value === 'boolean') {
                    value = field.value;
                }
                break;

            default:
                break;
        }

        if (value !== null) {
            accumulator.push({
                id: field.id,
                datatype: field.datatype,
                value: value
            })
        }

        return accumulator;

    }, []);
}
import { i18n } from '@lingui/core';


export function getRegistrationQuestions(eventId) {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/store_api/events/${eventId}/registration?locale=${i18n.locale}`)
            .then(async res => {
                if (res.status !== 200) throw new Error();
                const { required, custom } = await res.json();
                resolve({ required, custom });
            })
            .catch((e) => {
                reject(e)
            })
    })
}